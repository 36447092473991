import cloneDeep from 'lodash/cloneDeep'
import lodashSet from 'lodash/set'
import times from 'lodash/times'
import { ECountryCode, IRichTextElement } from '..'
import { IPersonalDataTagDefinitionComponent } from '../components/data/personalDataTagDefinition'

const sampleParagraphs = [
  'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Donec tristique quam odio.',
  'Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.',
  'Morbi purus elit, tempor quis commodo id, pulvinar id dolor. Etiam in vulputate augue, sed convallis dui.',
  'Aliquam in magna sed neque bibendum vulputate vitae id metus. Donec tristique quam odio. Sed ullamcorper lacus sed urna rhoncus interdum. Nullam id cursus dolor, ut posuere lectus.',
  'Donec pulvinar suscipit sem at congue. Morbi semper velit sed mauris efficitur, ultricies faucibus neque sollicitudin.',
  'Nam viverra posuere orci ac tristique. Fusce ultricies, odio non ullamcorper maximus, ex sapien rutrum augue, nec consectetur erat ante a tortor.',
  'Nunc ornare, libero ut pretium varius, ex sem pulvinar mauris, id tincidunt eros turpis vitae lacus. Aliquam in magna sed neque bibendum vulputate vitae id metus.',
  'Praesent nec justo feugiat, tempus urna tincidunt, suscipit risus. Nullam eleifend nisi risus, quis sollicitudin nunc lobortis in. Sed nibh massa, consequat id lacus maximus, semper auctor erat.',
  'Maecenas nec nisl orci. Pellentesque convallis ipsum et tortor sodales, id hendrerit justo congue. Morbi eget sapien nisi.',
  'Curabitur justo elit, malesuada vitae magna a, dignissim posuere nisi. Nullam luctus pretium massa, id finibus est condimentum laoreet.',
  'Sed ante erat, pellentesque at velit a, varius sodales lectus. Mauris tincidunt, magna eu imperdiet iaculis, neque nunc interdum elit, in commodo lorem augue in lectus.',
  'Cras pulvinar malesuada enim et semper. Donec aliquam magna libero, non porta metus porta at.'
]

export const buildRichTitleNode = (
  text = '',
  type = 'heading-two'
): IRichTextElement => ({
  type,
  children: [{ text }]
})

export const buildRichTextNode = (
  text = '',
  type = 'paragraph'
): IRichTextElement => ({
  type,
  children: [{ text }]
})

export const buildSampleParagraphs = (paragraphs: number | string[] = 2) =>
  Array.isArray(paragraphs)
    ? paragraphs.map(paragraph => buildRichTextNode(paragraph))
    : times(Math.min(sampleParagraphs.length, paragraphs)).map(index =>
        buildRichTextNode(sampleParagraphs[index])
      )

export const buildSampleText = (
  paragraphs: number | string[] = 2,
  heading = 'Section Heading'
): IRichTextElement[] =>
  [
    heading && buildRichTextNode(heading, 'heading-three'),
    ...buildSampleParagraphs(paragraphs)
  ].filter(Boolean)

export const parseCountryCode = (countryCode: string): string => {
  if (!countryCode) return null

  switch (countryCode) {
    case 'GB':
      return 'uk'
    default:
      return countryCode.toLowerCase()
  }
}

export const restrictPersonalDataOptions = (
  options: IPersonalDataTagDefinitionComponent[],
  countryCode: ECountryCode
) => {
  const lowerCasedCountryCode = parseCountryCode(countryCode)
  const isAUNZ =
    lowerCasedCountryCode === ECountryCode.au ||
    lowerCasedCountryCode === ECountryCode.nz
  return isAUNZ ? options : options.slice(0, 1)
}

export const set = <T extends object>(
  object: T,
  path: string | any[],
  value: any
): T => lodashSet(cloneDeep(object), path, value)

export const flattenObj = data => {
  const isObject = data =>
    Object.prototype.toString.call(data) === '[object Object]'
  const isArray = data =>
    Object.prototype.toString.call(data) === '[object Array]'

  const flatten = data => {
    if (!data.attributes) return data

    return {
      id: data.id,
      ...data.attributes
    }
  }

  if (isArray(data)) {
    return data.map(item => flattenObj(item))
  }

  if (isObject(data)) {
    if (isArray(data.data)) {
      data = [...data.data]
    } else if (isObject(data.data)) {
      data = flatten({ ...data.data })
    } else if (data.data === null) {
      data = null
    } else {
      data = flatten(data)
    }

    for (const key in data) {
      data[key] = flattenObj(data[key])
    }

    return data
  }

  return data
}
