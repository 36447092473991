import React from 'react'

function DisabledAdmin() {
  return (
    <div>
      <h1 style={{textAlign: 'center', margin: '100px auto', maxWidth: '800px'}}>
      Site Builder Admin is currently in maintenance mode for essential updates and will be available shortly.
      </h1>
    </div>
  )
}

export default DisabledAdmin