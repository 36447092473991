import get from 'lodash/get'
import orderBy from 'lodash/orderBy'
import sample from 'lodash/sample'
import client from '../client'
import { IAttachment, IFile, ISite } from '..'
import { snakeCaseParams } from '../params'
import qs from 'qs'
import { flattenObj } from '../utils'

export interface IAttachmentResponse {
  category: string
  created_at: string
  // TODO bring back the types
  file: any
  id: string
  site: ISite
  title: string
  type: 'file' | 'image'
  updated_at: string
}

interface IAttachmentRequest {
  file: File
  site: string
  title: string
  type: 'file' | 'image'
}

const query = qs.stringify(
  {
    populate: { file: { populate: '*' }, site: { populate: '*' } }
  },

  {
    encodeValuesOnly: true // prettify URL
  }
)

export const deserializeAttachment = (
  rawData: IAttachmentResponse
): IAttachment => {
  const data = flattenObj(rawData)

  return {
    category: data.category,
    createdAt: data.created_at,
    file: data.file?.data?.attributes
      ? { id: data.file.data.id, ...data.file.data.attributes }
      : data.file,
    id: data.id,
    site: data.site,
    title: data.title,
    type: data.type || 'image',
    updatedAt: data.updated_at,
    url: data.file?.url
  }
}

export const prepareAttachment = (data: IAttachmentRequest) => {
  const formData = new FormData()

  formData.append('files.file', data.file, data.file.name)
  formData.append(
    'data',
    JSON.stringify({
      site: data.site,
      title: data.title,
      type: data.type || 'image'
    })
  )

  return formData
}

export const getImage = (
  attachments: IAttachment[],
  category: string,
  index = 0
): Partial<IFile> =>
  get(
    orderBy(
      attachments.filter(attachment => attachment.category === category),
      ['title', 'id'],
      ['asc', 'asc']
    )[index],
    'file',
    {}
  )

export const getRandomImage = (
  attachments: IAttachment[],
  category?: string
): Partial<IFile> =>
  get(
    sample(
      attachments.filter(attachment =>
        category ? attachment.category === category : true
      )
    ),
    'file',
    {}
  )

export const fetchAttachments = (site?: string): Promise<IAttachment[]> =>
  client
    .get(site ? `/attachments/${site}?${query}` : `/attachments?${query}`)
    .then(res => {
      return res.data
    })
    .then(data => data.map(deserializeAttachment))

export const fetchPlaceholderAttachments = (
  category?: string
): Promise<IAttachment[]> => {
  return client
    .get('/attachments/placeholders', {
      params: snakeCaseParams({ category, populate: '*' })
    })
    .then(res => res.data.data)
    .then(data => {
      return data.map(item =>
        deserializeAttachment({ id: item.id, ...item.attributes })
      )
    })
}

export const createAttachment = (
  data: IAttachmentRequest
): Promise<IAttachment> =>
  client
    .post(`/attachments?${query}`, prepareAttachment(data), {
      headers: { 'Content-Type': 'multipart/form-data' }
    })
    .then(response => deserializeAttachment(response.data))

export const deleteAttachment = (data: IAttachment) =>
  client.delete(`/attachments/${data.id}`)
