import { EStatus } from './status'
import { ELinkType } from './components'
import { IContentComponent } from './components/content'
import { IDesignColorsComponent } from './components/design/colors'
import { IDesignFontComponent } from './components/design/font'
import { IDesignFontsComponent } from './components/design/fonts'
import { IGlobalMetaComponent } from './components/global/meta'
import { IGlobalBadgesComponent } from './components/global/badges'
import { IGlobalCustomDomainComponent } from './components/global/customDomain'
import { IGlobalFitnessComponent } from './components/global/fitness'
import { IGlobalFooterComponent } from './components/global/footer'
import { IGlobalHeaderComponent } from './components/global/header'
import { IGlobalRegistrationComponent } from './components/global/registration'
import { IGlobalThankYouComponent } from './components/global/thankYou'
import { IGlobalTrackingComponent } from './components/global/tracking'
import { IGlobalEmailCoverComponent } from './components/global/emailCover'
import { IGlobalEmailCampaignLogoComponent } from './components/global/emailCampaignLogo'

export { EFieldType } from './components'

export enum EState {
  draft = 'draft',
  edit = 'edit',
  published = 'published'
}

export enum ECountryCode {
  au = 'au',
  ca = 'ca',
  ie = 'ie',
  nz = 'nz',
  uk = 'uk',
  us = 'us'
}

export enum ECurrencyCode {
  au = 'AUD',
  ca = 'CAD',
  ie = 'EUR',
  nz = 'NZD',
  uk = 'GBP',
  us = 'USD'
}

export interface IResource {
  id: string
  createdAt: string
  updatedAt: string
}

interface IAnalytics {
  id: string
  type: string
}

export interface IOrganisation extends IResource {
  charityId: string
  charity: ICharity
  iamGroup: string
  image: string
  title: string
  slug: string
  sites: ISite[]
  colors: ISiteColors
  fonts: ISiteFonts
  privacyUrl: string
  termsUrl: string
  users: IUser[]
  countryCode?: ECountryCode
  currencyCode?: ECurrencyCode
}

export interface ICharity {
  address: {
    line1: string
    line2: string
    townOrCity: string
    countyOrState: string
    country: string
    postcodeOrZipcode: string
  }
  countryCode: ECountryCode
  currencyCode: ECurrencyCode
  dateAddedToJustGiving: string
  description: string
  displayName: string
  emailAddress: string
  id: number
  impactStatementWhat: string
  isApproved: boolean
  logoAbsoluteUrl: string
  logoUrl: string
  name: string
  pageShortName: string
  profilePageUrl: string
  registrationNumber: string
  showInSearch: boolean
  thankyouMessage: string
  themeColour: {
    alpha: number
    red: number
    green: number
    blue: number
  }
  websiteUrl: string
}

export interface IRole {
  description: string
  id: string
  name: string
  type: string
}

export interface IUser extends IResource {
  firstName: string
  image: string
  lastName: string
  organisations: IOrganisation[]
  provider: string
  role: IRole
  username: string
  iamTokenExpiresAt: string | null
  dateOfBirth?: string | null
  phoneNumber?: string | null
}

export interface ISite extends IResource {
  attachments?: IAttachment[]
  author: IUser
  badges: IGlobalBadgesComponent
  campaignGuid: string
  campaign: ISiteCampaign
  colors: ISiteColors
  countryCode: ECountryCode
  customDomain: IGlobalCustomDomainComponent
  defaults: ISiteDefaults
  domain: string
  eventId: string
  fitness: IGlobalFitnessComponent
  fonts: ISiteFonts
  footer: IGlobalFooterComponent
  header: IGlobalHeaderComponent
  isLive: boolean
  meta: IGlobalMetaComponent
  name: string
  registrationConfig: IGlobalRegistrationComponent
  organisation: IOrganisation
  owner: IUser
  production?: boolean
  siteStatus: 'archived' | 'draft' | 'published'
  slug: string
  status: EStatus
  teamBadges: IGlobalBadgesComponent
  thankYou: IGlobalThankYouComponent
  tracking: IGlobalTrackingComponent
  type: string
  url: string
  siteScreenshot: string
  gqlClientKey: string
  emailCover: IGlobalEmailCoverComponent
  emailCampaignLogo?: IGlobalEmailCampaignLogoComponent
}

export interface ISiteDefaults {
  button: IButtonDesign
  input: IInputDesign
}

interface ICampaignFundraisingDefaults {
  heroImage: {
    imageName: string
    title: string
  }
  story: string
  targetAmount: number
  title: string
}

interface ICampaignFundraising {
  defaults: ICampaignFundraisingDefaults
  fundraisingPageUrl: string
  hasDefaults?: boolean
  isBranded?: boolean
  isEnabled: boolean
  isFundraisingPageRedirectEnabled?: boolean
  isStartFundraisingUrlEnabled?: boolean
  startFundraisingUrl: string
}

export interface IDonationSummary {
  totalAmount: number
  totalGiftAid: number
  totalNumberOfDonations: number
  directDonationAmount: number
  directDonationGiftAid: number
  numberOfDirectDonations: number
  fundraiserRaisedAmount: number
  numberOfFundraiserDonations: number
  offlineAmount: number
  offlineAmountUpdated?: boolean | null
}

export interface ISiteCampaign extends ICampaignFundraisingDefaults {
  legacyId: string
  campaignGuid: string
  currencyCode: ECurrencyCode
  eventId: string
  fundraising?: ICampaignFundraising // deprecated
  fundraisingSettings: ICampaignFundraising
  offlineAmount: number
  shortName: string
  startDate: string
  endDate: string
  launchDate: string
  summary: string
  charities: any
  branding: {
    headerImage: string
  }
  targetWithCurrency?: {
    amount: number | string
    isVisible: boolean
    currencyCode: string
  }
  donationSummary: IDonationSummary
  isSitebuilder: boolean
  analytics: IAnalytics[]
  status: string
}

export type ISiteColors = IDesignColorsComponent
export type ISiteFonts = IDesignFontsComponent
export type ISiteFont = IDesignFontComponent

export interface IPage extends IResource {
  author: IUser
  content: IContentComponent[]
  isLive: boolean
  name: string
  path: string
  site: string
  slug: string
  status: EStatus
  type: string
}

export interface IAttachment extends IResource {
  category?: string
  file: IFile
  site: ISite
  title: string
  type: 'file' | 'image'
  url: string
}

export interface IFile {
  name: string
  alternativeText: string
  caption: string
  ext: string
  width: number
  height: number
  url: string
  formats: {
    thumbnail: IFileFormat
    large: IFileFormat
    medium: IFileFormat
    small: IFileFormat
  }
}

export interface IFileFormat {
  hash: string
  ext: string
  mime: string
  width: number
  height: number
  size: number
  path: string
  url: string
}

export interface ILink {
  page?: string
  target?: '_top' | '_blank'
  type: ELinkType
  url?: string
}

// Theme types
export interface IColors {
  primary: string
  secondary: string
  tertiary: string
  light: string
  dark: string
  offWhite: string
  facebook: string
  twitter: string
  instagram: string
  youtube: string
  linkedin: string
  google: string
  vimeo: string
  fitbit: string
  mapmyfitness: string
  strava: string
  twitch: string
  eventbrite: string
  slack: string
  whatsapp: string
  pinterest: string
  messenger: string
  reddit: string
  danger: string
  success: string
  blackbaud: string
  everydayhero: string
  justgiving: string
  shade: string
  inactive: string
  defaultBlue: string
  tint: string
  darkGray: string
  mediumGray: string
  gray: string
  lightGray: string
  transparent: 'transparent'
  inherit: 'inherit'
}

export type IColor = keyof IColors

export interface IFonts {
  heading: string
  body: string
  button: string
}

export type IFont = keyof IFonts

export interface ITreatments {
  heading: { [key: string]: any }
  body: { [key: string]: any }
  button: { [key: string]: any }
}

export type ITreatment = keyof ITreatments

export interface IAlignments {
  left: string
  center: string
  right: string
  start?: string
  end?: string
  baseline?: string
  between?: string
  around?: string
  evenly?: string
  top?: string
  bottom?: string
  base?: string
  last?: string
  inherit: 'inherit'
}

export type IAlignment = keyof IAlignments

export interface IDirections {
  horizontal: string
  vertical: string
}

export type IDirection = keyof IDirections

export interface IDesign {
  background?: IColor
  foreground?: IColor
  spacing?: number[]
  alignment?: IAlignment
  maxWidth?: number
  bold?: boolean
  italic?: boolean
  strikethrough?: boolean
  underline?: boolean
  quote?: boolean
  numberedList?: boolean
  bulletedList?: boolean
  fontSize?: string
  backgroundImage?: IFile
  template?: string
  classNames?: { [key: string]: IClassName }
}

export interface IInput {
  key: string
  Input: any
  label: string
}

export interface IClassName {
  foreground?: IColor
  size?: number
  treatment?: ITreatment
}

export interface IButtonDesign {
  background?: IColor
  foreground?: IColor
  size?: number
  spacing?: number[]
  borderRadius?: number
}

export interface IInputDesign {
  spacing?: number[]
  borderRadius?: number
}

export interface IRichTextChild {
  text: string
}

export interface IRichTextElement {
  type?: string
  children?: IRichTextElement[] | IRichTextChild[]
  [key: string]: any
}
