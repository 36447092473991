import { v4 as uuid } from 'uuid'
import { Reducer } from 'redux'
import { useSelector } from 'react-redux'
import { IState, StoreDispatch } from '..'

// Config
export enum ETheme {
  FAILURE = 'failure',
  NOTICE = 'notice',
  SUCCESS = 'success'
}

interface IFlash {
  content: string
  theme: ETheme
  id: string
}

export interface IFlashState {
  messages: IFlash[]
}

enum EActionTypes {
  CLEAR_FLASH = 'admin/flash/CLEAR_FLASH',
  SET_FLASH = 'admin/flash/SET_FLASH'
}

// Actions
interface IClearFlashAction {
  type: EActionTypes.CLEAR_FLASH
  payload: string
}

interface ISetFlashAction {
  type: EActionTypes.SET_FLASH
  payload: Partial<IFlash>
}

type IFlashAction = IClearFlashAction | ISetFlashAction

export const clearFlash = (id: string): IFlashAction => ({
  type: EActionTypes.CLEAR_FLASH,
  payload: id
})

export const setFlash = (content: string, theme = ETheme.SUCCESS) => (
  dispatch: StoreDispatch
) => {
  const id = uuid()

  dispatch({
    type: EActionTypes.SET_FLASH,
    payload: { content, theme, id }
  })

  setTimeout(
    () => dispatch({ type: EActionTypes.CLEAR_FLASH, payload: id }),
    5000
  )
}

// Selectors
export const useFlash = () =>
  useSelector((state: IState) => state.flash.messages)

// Reducer
const initialState = {
  messages: []
}

const reducer: Reducer<IFlashState> = (
  state = initialState,
  action: IFlashAction
) => {
  switch (action.type) {
    case EActionTypes.CLEAR_FLASH:
      return initialState
    case EActionTypes.SET_FLASH:
      return { messages: [...state.messages, action.payload] }
    default:
      return state
  }
}

export default reducer
